import { DateFormatter } from '@internationalized/date'
import { isMatch, parseISO } from 'date-fns'
import { PayoutWithJoins } from './custom_types'
import { Enums, Tables } from './db_types'

export const isManualPayout = (payout?: Tables<'payouts'> | PayoutWithJoins) => {
  return !payout?.compensation_rule_id && !payout?.target_id
}

export const isClawbackPayout = (payout?: Tables<'payouts'> | PayoutWithJoins) => {
  return !!payout?.clawback_id
}

export const firstToUpperCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const breakOnUnderscore = (str: string) => {
  return str.split('_').join(' ')
}

export function removeAccents(str: string): string {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export function removeEmptyValues<T extends Record<string, any>>(obj: T): T {
  return Object.entries(obj)
    .filter(([_, v]) => v != null && v !== '' && v !== undefined)
    .reduce((acc: Record<string, any>, [k, v]) => ({ ...acc, [k]: v }), {}) as T
}

export function removeKeys<T extends object, K extends keyof T>(
  obj: T,
  keysToRemove: K[]
): Omit<T, K> {
  const newObj = { ...obj }
  for (const key of keysToRemove) {
    delete newObj[key]
  }
  return newObj
}

export const removeObjectFields = (obj: Record<string, any>, fields?: string[]) => {
  const newObj = { ...obj }
  for (const field of Object.keys(newObj)) {
    if (newObj[field]?.constructor === Object && !fields?.includes(field) && field !== 'meta') {
      delete newObj[field]
    } else if (newObj[field]?.constructor === Object && 'id' in newObj[field]) {
      delete newObj[field]['id']
    }
  }
  return newObj
}

export function flattenObject(
  obj: Record<string, any>,
  parentKey = '',
  result = {} as Record<string, any>
) {
  if (obj.constructor !== Object) {
    return obj
  }

  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
      // Recursive call for nested objects, appending `parentKey` with current `key`
      flattenObject(obj[key], `${parentKey}${key}_`, result)
    } else if (Array.isArray(obj[key])) {
      obj[key].forEach((item: any, index: number) => {
        if (
          Object.keys(item).length === 2 &&
          'label' in item &&
          ('sum' in item || 'count' in item)
        ) {
          flattenObject(
            'sum' in item ? { sum: item.sum } : { count: item.count },
            `${parentKey}${key}_${item.label}_`,
            result
          )
        } else {
          flattenObject(item, `${parentKey}${key}_${index}_`, result)
        }
      })
    } else {
      // Direct assignment for non-object types or null values
      result[`${parentKey}${key}`] = obj[key]
    }
  }
  return result
}

export const getLocaleFromLanguage = (lang?: string) => {
  const locales: {
    [index: string]: {
      locale: string
      options?: { [index: string]: any }
    }
  } = {
    es: { locale: 'es-ES', options: { useGrouping: 'always' } },
    en: { locale: 'en-US' }
  }

  return locales[lang ?? 'en'] ?? 'en-US'
}

export function formatDateAndNumbers(
  obj: Record<string, any>,
  language: string
) {
  if (language !== 'en') {
    const locale = getLocaleFromLanguage(language).locale
    return Object.keys(obj).reduce((acc, key) => {
      if (key !== 'id' && !key.endsWith('_id') && !isNaN(Number(obj[key]))) {
        acc[key] = new Intl.NumberFormat(locale, {
          style: 'decimal',
          maximumFractionDigits: 2,
          useGrouping: false
        }).format(obj[key])
      } else if (isMatch(obj[key], 'yyyy-MM-dd')) {
        acc[key] = new DateFormatter(locale).format(parseISO(obj[key]))
      } else {
        acc[key] = obj[key]
      }
      return acc
    }, {} as Record<string, any>)
  }

  return obj
}

export function removeAutoDateFields(obj: Record<string, any>) {
  return Object.keys(obj).reduce((acc, key) => {
    if (!key.endsWith('created_at') && !key.endsWith('updated_at')) {
      acc[key] = obj[key]
    }
    return acc
  }, {} as Record<string, any>)
}

export function abbreviateNumber(num: number): string {
  if (num >= 1_000_000_000) {
    return (num / 1_000_000_000).toFixed(0).replace(/\.0$/, '') + 'B'
  } else if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(0).replace(/\.0$/, '') + 'M'
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(0).replace(/\.0$/, '') + 'K'
  } else {
    return num.toFixed(0).toString()
  }
}

export const comparePeriods = (period1: Enums<'period'>, period2: Enums<'period'>) => {
  const periods = ['month', 'quarter', 'half_year', 'year']
  return periods.indexOf(period1) - periods.indexOf(period2)
}

export const getCompensationValue = (tierValue: string | number, attainment: number): number => {
  const formulaWithVariablesWithValues = String(tierValue).replaceAll(
    /Achievement/gi,
    attainment.toString()
  )

  return new Function(`return ${formulaWithVariablesWithValues}`)()
}

export function isValidCurrencyCode(currency: string) {
  try {
    new Intl.NumberFormat('en-US', { style: 'currency', currency })
    return true
  } catch (e) {
    return false
  }
}
