import { DatabaseTableName, SupabaseTableHookQueryOperators } from '@dolfin/business/custom_types'
import { isArrayLike, isNumber } from 'lodash'
import { format } from 'date-fns'
import { PostgrestFilterBuilder } from '@supabase/postgrest-js'
import { removeAccents } from '@dolfin/business/utils'
import { GenericSchema } from '@supabase/supabase-js/dist/module/lib/types'

export const getPkeyForTable = (tableName: DatabaseTableName) => {
  switch (tableName) {
    case 'deals':
      return 'deal_id'
    case 'activities':
      return 'activity_id'
    case 'invoices':
      return 'invoice_id'
    default:
      return 'id'
  }
}

export const ORG_FILTERS = [
  'activities',
  'csv_mappers',
  'custom_fields',
  'deals',
  'invitations',
  'invoices',
  'kpis',
  'kpis_tables',
  'metrics',
  'payouts',
  'plans',
  'profile_org_variable_salary',
  'statements',
  'targets',
  'table_config',
  'teams',
  'validation_steps'
]

export const buildQuery = <
  Schema extends GenericSchema,
  Row extends Record<string, any>,
  Result
>(
  //<T extends PostgrestFilterBuilder<unknown, unknown, unknown>> ( //<T extends PostgrestFilterBuilder<Database['public'], Record<string, any>, any>> (
  query: PostgrestFilterBuilder<Schema, Row, Result>,
  options: {
    selectedOrganizationId: number
    tableName: string
    skipOrgFilter?: boolean
  },
  filtersAndSorting: SupabaseTableHookQueryOperators
) => {
  const {
    order,
    range,
    eqFilters,
    neqFilters,
    isFilters,
    gtFilters,
    ltFilters,
    inFilters,
    containsFilters,
    ilikeFilters,
    timeRange,
    orFilter,
    notFilter,
    filters,
    limit,
    textSearch,
    rangeLt,
    rangeGt,
    overlaps
  } = filtersAndSorting

  if (order && Array.isArray(order)) {
    order.forEach(o => {
      query = query.order(o.column, o.options)
    })
  } else {
    query = query.order('created_at', { ascending: false })
  }

  if (
    options.selectedOrganizationId &&
    ORG_FILTERS.includes(options.tableName) &&
    !options.skipOrgFilter
  ) {
    query = query.eq('organization_id', options.selectedOrganizationId)
  }

  if (range && isNumber(range?.from) && isNumber(range?.to)) {
    query = query.range(range.from, range.to)
  }

  if (eqFilters) {
    eqFilters.forEach(filter => {
      query = query.eq(filter.column, filter.value)
    })
  }

  if (neqFilters) {
    neqFilters.forEach(filter => {
      query = query.neq(filter.column, filter.value)
    })
  }

  if (isFilters) {
    isFilters.forEach(filter => {
      if (!!filter.negate) {
        query = query.not(filter.column, 'is', filter.value)
      } else {
        query = query.is(filter.column, filter.value)
      }
    })
  }

  if (gtFilters) {
    gtFilters.forEach(filter => {
      if (filter.orEqual) {
        query = query.gte(filter.column, filter.value)
      } else {
        query = query.gt(filter.column, filter.value)
      }
    })
  }

  if (ltFilters) {
    ltFilters.forEach(filter => {
      if (filter.orEqual) {
        query = query.lte(filter.column, filter.value)
      } else {
        query = query.lt(filter.column, filter.value)
      }
    })
  }

  if (inFilters) {
    inFilters.forEach(filter => {
      query = query.in(filter.column, filter.values)
    })
  }

  if (containsFilters) {
    containsFilters.forEach(filter => {
      query = query.contains(filter.column, filter.values)
    })
  }

  if (ilikeFilters) {
    ilikeFilters.forEach(filter => {
      query = query.ilike(filter.column, filter.value)
    })
  }

  if (timeRange) {
    const { to, from, selectValue } = timeRange

    if (from) {
      query = query.gte(selectValue ?? 'created_at', format(from, 'yyyy-MM-dd'))
    }

    if (to) {
      query = query.lte(selectValue ?? 'created_at', format(to, 'yyyy-MM-dd'))
    }
  }

  if (orFilter) {
    query = query.or(
      typeof orFilter === 'string' ? orFilter : orFilter[0],
      typeof orFilter === 'string' ? undefined : orFilter[1]
    )
  }

  if (notFilter) {
    query = query.not(...notFilter)
  }

  if (filters) {
    if (Array.isArray(filters)) {
      filters.forEach(f => {
        query = query.filter(f.column, f.operator, f.value)
      })
    } else {
      query = query.filter(filters.column, filters.operator, filters.value)
    }
  }

  if (limit) {
    query = query.limit(limit)
  }

  if (textSearch) {
    query = query.textSearch(
      textSearch.column,
      removeAccents(textSearch.query),
      textSearch.options
    )
  }

  if (rangeLt) {
    query = query.rangeLt(rangeLt.column, rangeLt.value)
  }

  if (rangeGt) {
    query = query.rangeGt(rangeGt.column, rangeGt.value)
  }

  if (overlaps) {
    query = query.overlaps(overlaps.column, overlaps.value)
  }
  return query
}

export async function* getAllPaginatedPages<
  Schema extends GenericSchema,
  Row extends Record<string, any>,
  Result extends Iterable<Row>
>(
  query: PostgrestFilterBuilder<Schema, Row, Result>,
  start: number = 0,
  end: number = Infinity,
  step: number = 1000
) {
  let _start = start
  let _end = Math.min(_start + step - 1, end)
  while (_start <= end) {
    const { data, error } = await query.range(_start, _end)
    if (error) throw error
    if (!isArrayLike(data)) throw new Error('Data is not array-like')
    if (data.length === 0) break
    yield data
    _start += step
    _end = Math.min(_end + step, end)
  }
}

export async function* getAllPaginated<
  Schema extends GenericSchema,
  Row extends Record<string, any>,
  Result extends Iterable<Row>
>(
  query: PostgrestFilterBuilder<Schema, Row, Result>,
  start: number = 0,
  end: number = Infinity,
  step: number = 1000
) {
  for await (const page of getAllPaginatedPages(query, start, end, step)) {
    for (const row of page) {
      yield row
    }
  }
}
