import { IconProps } from '../constants'
import SVGContainer from './svg-container'

const Upload = (props: IconProps) => (
  <SVGContainer filled {...props}>
    <path d='M11.47 1.72a.75.75 0 011.06 0l3 3a.75.75 0 01-1.06 1.06l-1.72-1.72V7.5h-1.5V4.06L9.53 5.78a.75.75 0 01-1.06-1.06l3-3zM11.25 7.5V15a.75.75 0 001.5 0V7.5h3.75a3 3 0 013 3v9a3 3 0 01-3 3h-9a3 3 0 01-3-3v-9a3 3 0 013-3h3.75z' />
  </SVGContainer>
)

export default Upload
