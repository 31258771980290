import ArchiveBox from './archive-box'
import ArrowPointingOut from './arrows-pointing-out'
import ArrowSmallDown from './arrow-small-down'
import ArrowSmallLeft from './arrow-small-left'
import ArrowSmallRight from './arrow-small-right'
import ArrowTrendingUp from './arrows-trending-up'
import ArrowUturnRight from './arrow-uturn-right'
import CheckCircle from './check-circle'
import ChevronDown from './chevron-down'
import ChevronLeft from './chevron-left'
import ChevronRight from './chevron-right'
import ChevronUp from './chevron-up'
import CircleArrowDown from './circle-arrow-down'
import Close from './close'
import CloudArrowDown from './cloud-arrow-down'
import Cog from './cog'
import Cog8Tooth from './cog-8-tooth'
import CubeTransparent from './cube-transparent'
import CursorArrowRays from './cursor-arrow-rays'
import Db from './db'
import DocumentCheck from './document-check'
import Dot from './dot'
import DotsVertical from './dots-vertical'
import DotsDrag from './dots-drag'
import Download from './download'
import Edit from './edit'
import EllipsisHorizontal from './ellipsis-horizontal'
import ExclamationCircle from './exclamation-circle'
import Filter from './filter'
import Fire from './fire'
import Flag from './flag'
import Gift from './gift'
import Globe from './globe'
import Home from './home'
import InformationCircle from './information-circle'
import InformationCircleSolid from './information-circle-solid'
import Magnify from './magnify'
import MinusSmall from './minus-small'
import Play from './play'
import PlayCircle from './play-circle'
import Pencil from './pencil'
import PencilFilled from './pencil-filled'
import Plus from './plus'
import PlusSmall from './plus-small'
import PuzzlePiece from './puzzle-piece'
import RectangleStack from './rectangle-stack'
import Sort from './sort'
import Sparkles from './sparkles'
import Star from './star'
import Trash from './trash'
import User from './user'
import UserAdd from './user-add'
import UserGroup from './user-group'
import ChartBar from './chart-bar'
import QuestionMarkCircle from './question-mark-circle'
import Copy from './copy'
import Undo from './undo'
import Redo from './redo'
import Upload from './upload'
import ListBullet from './list-bullet'
import Detail from './detail'

export {
  ArchiveBox,
  ArrowPointingOut,
  ArrowSmallDown,
  ArrowSmallLeft,
  ArrowSmallRight,
  ArrowTrendingUp,
  ArrowUturnRight,
  CheckCircle,
  ChevronDown,
  ChartBar,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  CircleArrowDown,
  Close,
  Cog,
  Cog8Tooth,
  Copy,
  CloudArrowDown,
  CubeTransparent,
  CursorArrowRays,
  Db,
  Detail,
  DocumentCheck,
  Dot,
  DotsVertical,
  Download,
  DotsDrag,
  QuestionMarkCircle,
  Edit,
  EllipsisHorizontal,
  ExclamationCircle,
  Filter,
  Fire,
  Flag,
  Gift,
  Globe,
  Home,
  InformationCircle,
  InformationCircleSolid,
  ListBullet,
  Magnify,
  MinusSmall,
  Play,
  PlayCircle,
  Pencil,
  PencilFilled,
  Plus,
  PlusSmall,
  PuzzlePiece,
  RectangleStack,
  Redo,
  Sort,
  Sparkles,
  Star,
  Trash,
  User,
  UserAdd,
  UserGroup,
  Upload,
  Undo
}
