import React, { ReactElement } from 'react'
import { tv } from 'tailwind-variants'
import {
  Button as RACButton,
  ButtonProps as RACButtonProps,
  composeRenderProps
} from 'react-aria-components'
import { pressedRing } from '@/src/stories/utils'
import { Loading01 } from '@untitled-ui/icons-react'

export interface ButtonProps extends RACButtonProps {
  /**
   * button type
   */
  hierarchy?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'linkColor'
    | 'linkGray'
    | 'on-brand'
  /**
   * destructive?
   */
  destructive?: boolean
  /**
   * isDisabled?
   */
  isDisabled?: boolean
  /**
   * is loading?
   */
  isLoading?: boolean
  /**
   * How large should the button be?
   */
  size?: 'xsmall' | 'small' | 'medium' | 'large'
  /**
   * Button contents
   */
  label?: string
  /**
   * Button contents
   */
  dotColor?: string
  /**
   * Button contents
   */
  iconLeading?: ReactElement
  /**
   * Button contents
   */
  iconTrailing?: ReactElement
  /**
   * Optional click handler
   */
  onClick?: () => void
}

export const buttonStyles = tv({
  extend: pressedRing,
  base: 'flex items-center text-center gap-2 rounded-sm font-semibold cursor-pointer',
  variants: {
    destructive: {
      false: ''
    },
    isDisabled: {
      false: ''
    },
    size: {
      xsmall: 'text-xs px-2 py-1',
      small: 'text-sm px-3 py-2',
      medium: 'text-base px-4 py-2.5',
      large: 'text-lg px-6 py-3'
    },
    hierarchy: {
      primary: `bg-brand-700 text-neutral-0 
         hover:bg-brand-900 
         active:bg-brand-700`,
      secondary: `bg-neutral-0 text-neutral-700 border-neutral-300 border-solid border outline-none
         hover:bg-neutral-50 hover:text-neutral-800 
         active:bg-neutral-0`,
      tertiary: `bg-neutral-0 text-neutral-700 outline-none
         hover:bg-neutral-50 hover:text-neutral-800 
         active:text-neutral-700 active:bg-neutral-0`,
      linkColor: `p-0 text-highlight-500 outline-none 
         hover:highlight-500 
         active:highlight-500 active:bg-neutral-0`,
      linkGray: `text-neutral-700 hover:text-neutral-900 active:text-neutral-700 active:bg-neutral-0 outline-none`,
      'on-brand':
        'bg-neutral-800 text-neutral-0 hover:bg-brand-900 active:bg-brand-900'
    },
    grow: {
      true: 'w-full'
    }
  },
  defaultVariants: {
    hierarchy: 'primary'
  },
  compoundVariants: [
    {
      destructive: [true],
      hierarchy: 'primary',
      className: `bg-critical-500 text-neutral-0
         hover:bg-critical-700 
         active:bg-critical-500 `
    },
    {
      destructive: [true],
      hierarchy: 'secondary',
      className: `bg-neutral-0 text-critical-700 border-critical-300 border border-solid outline-none
         hover:bg-critical-50  hover:text-critical-800
         active:text-critical-700 active:bg-neutral-0 active:border-critical-300`
    },
    {
      destructive: [true],
      hierarchy: 'tertiary',
      className: `bg-neutral-0 hover:bg-critical-50 text-critical-700 hover:text-critical-800 active:bg-neutral-0 active:text-critical-700`
    },
    {
      destructive: [true],
      hierarchy: 'linkColor',
      className: `bg-neutral-0 text-critical-700 hover:text-critical-800 active:bg-neutral-0 active:text-critical-700`
    },
    {
      isDisabled: true,
      hierarchy: 'primary',
      className: `bg-neutral-100 hover:bg-neutral-100 active:bg-neutral-100 text-neutral-400 cursor-default`
    },
    {
      isDisabled: true,
      hierarchy: 'secondary',
      className: `bg-neutral-0 hover:bg-neutral-0 hover:text-neutral-400 active:bg-neutral-0 text-neutral-400 cursor-default border-neutral-100 border border-solid`
    },
    {
      isDisabled: true,
      hierarchy: 'tertiary',
      className: `bg-neutral-0 hover:bg-neutral-0 hover:text-neutral-400 active:text-neutral-400 active:bg-neutral-0 text-neutral-400 cursor-default`
    },
    {
      isDisabled: true,
      hierarchy: 'linkColor',
      className: `bg-neutral-0 hover:text-neutral-400 active:text-neutral-400 active:bg-neutral-0 text-neutral-400 cursor-default`
    },
    {
      isDisabled: true,
      hierarchy: 'linkGray',
      className: `bg-neutral-0 hover:text-neutral-400 active:text-neutral-400 active:bg-neutral-0 text-neutral-400 cursor-default`
    }
  ]
})

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  hierarchy = 'primary',
  destructive = false,
  size = 'medium',
  label,
  iconLeading,
  iconTrailing,
  dotColor,
  ...props
}: ButtonProps) => {
  return (
    <RACButton
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        buttonStyles({
          ...renderProps,
          hierarchy,
          className,
          destructive,
          size
        })
      )}>
      {!!iconLeading && (
        <span className={props.isLoading ? 'opacity-0' : ''}>
          {iconLeading}
        </span>
      )}
      {!!dotColor && (
        <span className={props.isLoading ? 'opacity-0' : ''}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='10'
            height='10'
            viewBox='0 0 10 10'
            fill='none'>
            <circle cx='5' cy='5' r='4' fill={dotColor} />
          </svg>
        </span>
      )}

      {!!label && (
        <div className={'grid place-center items-center'}>
          <div
            style={{
              gridArea: '1 / 1',
              opacity: props.isLoading ? 0 : 1
            }}>
            {label}
          </div>
          <div
            className={'flex items-center justify-center animate-spin'}
            style={{
              gridArea: '1 / 1',
              opacity: props.isLoading ? 1 : 0,
              width: '100%',
              height: '30px'
            }}>
            <Loading01 width={18} />
          </div>
        </div>
      )}

      {iconTrailing && (
        <span className={props.isLoading ? 'opacity-0' : ''}>
          {iconTrailing}
        </span>
      )}
    </RACButton>
  )
}
