import { Sizes, sizes, text } from '@/src/ui/avatar/constants'
import useSupabaseTable from '@/hooks/supabase-table'
import {
  Profile,
  ProfileInsert,
  ProfileWithJoins,
  ProfileWithOrganizationProfileInfoAndTeams
} from '@dolfin/business/custom_types'
import { QueryKeys } from '@/hooks/queryKeys'
import Image from 'next/image'
import { useOrgContext } from '@/hooks/state/organization'

interface AvatarProps {
  size?: Sizes
  userId?: string
  withDetails?: boolean
  withName?: boolean
  profileData?: Profile | ProfileWithOrganizationProfileInfoAndTeams
}

const Avatar = ({
  userId,
  profileData,
  withDetails,
  withName,
  size = Sizes.MEDIUM
}: AvatarProps) => {
  const { selectedProfile, selectedOrganizationId } = useOrgContext(state => ({
    selectedProfile: state.profile,
    selectedOrganizationId: state.id
  }))

  let eqFilters: {
    column: string
    value: string | number
  }[] = []

  if (userId) {
    eqFilters = [
      ...eqFilters,
      {
        column: 'id',
        value: userId
      }
    ]
  }

  const { isLoading, items: profiles } = useSupabaseTable<
    ProfileWithJoins,
    ProfileInsert
  >({
    enabled: !profileData && !!userId,
    tableName: 'profiles',
    queryKeys: [QueryKeys.profiles.get, userId],
    selectQuery: '*',
    eqFilters
  })

  const profile = profileData
    ? profileData
    : userId
    ? profiles?.[0]
    : selectedProfile

  return (
    <div className={'flex space-x-2 items-center'}>
      <div
        className={`relative bg-primary-negative border-2 rounded-full flex items-center justify-center shrink-0 ${
          sizes[size]
        } ${isLoading ? 'animate-pulse' : ''} overflow-hidden`}>
        {!isLoading &&
          profile &&
          (profile.img_url ? (
            <Image
              fill
              style={{ objectFit: 'cover' }}
              src={profile.img_url}
              alt={profile.name ?? profile.email}
            />
          ) : (
            <p className={`text-neutral-900 font-bold ${text[size]}`}>
              {(profile.name ?? profile.email)[0].toUpperCase()}
            </p>
          ))}
      </div>

      {(withDetails || withName) && (
        <div className={'flex flex-col items-start justify-center w-[70%]'}>
          {!!profile?.name && (
            <p className={'text-left font-semibold w-full truncate'}>
              {profile.name.slice(0, 1).toUpperCase() + profile.name.slice(1)}
            </p>
          )}
          {withDetails && !!profile?.email && (
            <p className={'text-xs text-left w-full truncate'}>
              {profile.email}
            </p>
          )}
        </div>
      )}
    </div>
  )
}

export default Avatar
