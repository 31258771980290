import {
  MENU_ITEM,
  MENU_ITEM_WITH_CHILDREN,
  SUBMENU_ITEM
} from '@/src/components/sidebar/sidebar.styles'
import { IconWrapper } from '@/src/stories/IconWrapper'
import { ChevronDown } from '@untitled-ui/icons-react'
import SubSidebar from '@/src/components/sidebar/subSidebar'
import Link from 'next/link'
import React from 'react'
import { SidebarLink } from '@/src/components/sidebar/constants'
import { TABS } from '@dolfin/business/custom_types'

const SidebarItem = ({
  item,
  isActive,
  isChildrenActive,
  visibleSubSidebar,
  setVisibleSubSidebar,
  closeSidebar,
  full
}: {
  item: SidebarLink
  isActive: (item: Omit<SidebarLink, 'id'> & { id: TABS | number }) => boolean
  isChildrenActive: (item: SidebarLink) => boolean
  visibleSubSidebar: string | null
  setVisibleSubSidebar: (id: string | null) => void
  closeSidebar: () => void
  full: boolean
}) => {
  const { id, label, href, Icon, subLinks } = item

  if (subLinks && subLinks.length > 0) {
    return (
      <li key={id}>
        <div>
          <div
            onClick={() => {
              setVisibleSubSidebar(visibleSubSidebar === id ? null : id)
            }}
            className={`${
              (isActive(item) || visibleSubSidebar === id) &&
              MENU_ITEM_WITH_CHILDREN.active
            } ${
              isChildrenActive(item) && MENU_ITEM_WITH_CHILDREN.childActive
            } ${
              !isChildrenActive(item) &&
              !(isActive(item) || visibleSubSidebar === id) &&
              MENU_ITEM_WITH_CHILDREN.regular
            }`}>
            <Icon width={18} className={'shrink-0'} />
            <p
              className={
                'md:hidden md:group-hover:block text-xs font-medium whitespace-nowrap overflow-hidden'
              }>
              {label}
            </p>
            {full && (
              <div className={'flex grow justify-end'}>
                <IconWrapper
                  width={16}
                  className={`transition-transform focus:outline-0 m-0 p-0 shrink-0 ${
                    (isActive(item) || visibleSubSidebar === id) && 'rotate-180'
                  }`}
                  Icon={ChevronDown}></IconWrapper>
              </div>
            )}
          </div>
        </div>

        <SubSidebar
          onClose={() => setVisibleSubSidebar(null)}
          full={full}
          label={label}
          visible={visibleSubSidebar === id}>
          {subLinks.map(subItem => {
            const { id, label, href, Icon } = subItem

            return (
              <li key={id}>
                <Link
                  shallow
                  onClick={() => closeSidebar()}
                  href={href}
                  className={
                    isActive(subItem)
                      ? SUBMENU_ITEM.active
                      : SUBMENU_ITEM.regular
                  }>
                  <Icon width={18} className={'shrink-0'} />

                  <p
                    className={
                      'block text-xs font-medium whitespace-nowrap overflow-hidden'
                    }>
                    {label}
                  </p>
                </Link>
              </li>
            )
          })}
        </SubSidebar>
      </li>
    )
  }

  return (
    <li key={id}>
      <Link
        shallow
        onClick={() => {
          closeSidebar()
          setVisibleSubSidebar(null)
        }}
        href={href}
        className={isActive(item) ? MENU_ITEM.active : MENU_ITEM.regular}>
        <Icon width={18} className={'shrink-0'} />

        <p
          className={
            'md:hidden md:group-hover:block text-xs font-medium whitespace-nowrap overflow-hidden'
          }>
          {label}
        </p>
      </Link>
    </li>
  )
}

export default SidebarItem
