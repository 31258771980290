import { IconProps } from '../constants'
import SVGContainer from './svg-container'

const Detail = (props: IconProps) => (
  <SVGContainer {...props} filled>
    <path d='M11.625 16.5a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75z' />
    <path
      fillRule='evenodd'
      d='M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zm6 16.5c.66 0 1.277-.19 1.797-.518l1.048 1.048a.75.75 0 001.06-1.06l-1.047-1.048A3.375 3.375 0 1011.625 18z'
      clipRule='evenodd'
    />
    <path d='M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z' />
  </SVGContainer>
)

export default Detail
