import { t } from '@lingui/macro'
import { memo, PropsWithChildren } from 'react'
import { Icons } from '@/src/ui'
import { BADGE_SIZES, BADGE_TYPES } from './badge.styles'
import { BadgeSizes, BadgeTypes } from './constants'
import { hexToRGB } from '@/utils/colors'

export type BadgeProps = {
  badgeType?: BadgeTypes
  className?: string
  onClose?: () => void
  color?: string
  size?: BadgeSizes
}

const Badge = (props: PropsWithChildren<BadgeProps>) => {
  const {
    badgeType = BadgeTypes.DEFAULT,
    children,
    className = '',
    color,
    onClose,
    size = BadgeSizes.SM
  } = props

  if (!BADGE_TYPES[badgeType])
    throw new Error(t`Badge type ${badgeType} not found!`)

  const badgeStyles = `${BADGE_TYPES[badgeType]} ${BADGE_SIZES[size]} ${className} flex gap-2 items-center`

  return (
    <div
      style={
        badgeType === BadgeTypes.CUSTOM && color
          ? {
              backgroundColor: `rgba(${hexToRGB(color)}, 0.2)`,
              color,
              border: `1px solid ${color}`
            }
          : undefined
      }
      className={badgeStyles}>
      {children}
      {onClose && (
        <button onClick={onClose}>
          <Icons.Close color='text-primary/70' size={8} />
        </button>
      )}
    </div>
  )
}

export default memo(Badge)
