import Avatar from '@/src/stories/Avatar'
import { format, formatDistanceToNow } from 'date-fns'
import { Button } from '@/src/stories/Button'
import { NotificationWithJoins } from '@dolfin/business/custom_types'
import { useSupabaseClient } from '@supabase/auth-helpers-react'
import { Database } from '@dolfin/business/db_types'
import { useOrgContext } from '@/hooks/state/organization'
import { t } from '@lingui/macro'
import CommentBox from '@/src/ui/comment-box'
import { useCallback } from 'react'
import { getInitials } from '@/src/stories/utils'

interface NotificationItemProps {
  notification: NotificationWithJoins
  writingComment: number | undefined
  setWritingComment: (writingComment: number | undefined) => void
  reloadNotifications: () => Promise<any>
}

const NotificationItem = ({
  notification,
  writingComment,
  setWritingComment,
  reloadNotifications
}: NotificationItemProps) => {
  const currentUserId = useOrgContext(state => state.profile?.id)
  const supabase = useSupabaseClient<Database>()

  const getDescription = useCallback(
    (notification: NotificationWithJoins) => {
      const subject = notification.profiles
        ? notification.profiles.name!
        : t`Someone`

      switch (notification.type) {
        case 'payout_flagged':
          if (notification.profile_id === currentUserId) {
            return t`You reported an issue on compensation #${notification.entity_id}`
          }
          return t`${subject} reported an issue on compensation #${notification.entity_id}`
        case 'statement_flagged':
          if (notification.profile_id === currentUserId) {
            return t`You reported an issue on statement #${notification.entity_id}`
          }
          return t`${subject} reported an issue on statement #${notification.entity_id}`
        case 'payout_resolved':
          if (notification.profile_id === currentUserId) {
            return t`You resolved the issue on the compensation #${notification.entity_id}`
          }
          return t`${subject} resolved the issue on the compensation #${notification.entity_id}`
        case 'statement_resolved':
          if (notification.profile_id === currentUserId) {
            return t`You resolved the issue on the statement #${notification.entity_id}`
          }
          return t`${subject} resolved the issue on the statement #${notification.entity_id}`
        case 'payout_modified':
          if (notification.profile_id === currentUserId) {
            return t`You modified compensation #${notification.entity_id}`
          }
          return t`${subject} modified compensation #${notification.entity_id}`
        case 'payout_paid':
          if (notification.profile_id === currentUserId) {
            return t`You marked compensation #${notification.entity_id} as paid`
          }
          return t`${subject} marked compensation #${notification.entity_id} as paid`
        case 'payout_validated':
          if (notification.profile_id === currentUserId) {
            return t`You validated compensation #${notification.entity_id}`
          }
          return t`${subject} validated compensation #${notification.entity_id}`
        case 'payout_adjustment_created':
          if (notification.profile_id === currentUserId) {
            return t`You adjusted compensation #${notification.entity_id}`
          }
          return t`${subject} adjusted compensation #${notification.entity_id}`
        case 'statement_approved':
          if (notification.profile_id === currentUserId) {
            return t`You approved statement #${notification.entity_id}`
          }
          return t`${subject} approved statement #${notification.entity_id}`
        case 'statement_paid':
          if (notification.profile_id === currentUserId) {
            return t`You marked statement #${notification.entity_id} as paid`
          }
          return t`${subject} marked statement #${notification.entity_id} as paid`
        default:
          return ''
      }
    },
    [currentUserId]
  )

  const markAsRead = async (notificationId: number) => {
    const { error } = await supabase
      .from('notifications_status')
      .update({ status: 'read' })
      .eq('notification_id', notificationId)
      .eq('profile_id', currentUserId!)

    if (!error) {
      await reloadNotifications()
    }
  }

  const insertComment = async (
    comment: string,
    notificationId: number,
    mentions: string[]
  ) => {
    const { data: insertedComment } = await supabase
      .from('comments')
      .insert({
        content: comment,
        notification_id: notificationId,
        profile_id: currentUserId!
      })
      .select('id')

    if (insertedComment) {
      await supabase.from('comments_mentions').insert(
        mentions.map(m => ({
          comment_id: insertedComment[0].id,
          profile_id: m
        }))
      )

      setWritingComment(undefined)
      await reloadNotifications()
    }
  }

  const onCommentCancel = () => {
    setWritingComment(undefined)
  }

  return (
    <div
      onClick={() => markAsRead(notification.id)}
      className='flex items-start gap-2 p-6 pl-4 border-t cursor-pointer hover:bg-primary-30 w-full'>
      <div className='flex items-start'>
        <div
          className={`rounded-full w-2 h-2${
            notification.notifications_status?.[0]?.status === 'unread'
              ? ' bg-blue-500'
              : ''
          }`}></div>
        {notification.profiles && (
          <Avatar
            size={'s'}
            imgUrl={notification.profiles.img_url ?? undefined}
            withTooltip={true}
            label={
              getInitials(
                notification.profiles.name ??
                  notification.profiles.email.split('@').join(' ')
              )!
            }
          />
        )}
      </div>
      <div
        className={'w-full flex flex-col items-start justify-start text-left'}>
        <p className='font-semibold break-normal text-sm'>
          {getDescription(notification)}
        </p>
        <p
          className='text-xs text-primary-500'
          title={format(
            new Date(notification.created_at),
            'MMM dd, yyyy HH:mm'
          )}>
          {formatDistanceToNow(new Date(notification.created_at), {
            addSuffix: true
          })}
        </p>

        {notification.comments?.map((comment, idx) => (
          <div
            key={`comment_${comment.id}`}
            className='text-sm text-primary-800 py-2 w-full flex flex-col gap-1'>
            {(idx > 0 || comment.profile_id !== notification.profile_id) && (
              <div className={'flex flex-row gap-4 justify-start items-center'}>
                {comment.profiles && (
                  <Avatar
                    size={'s'}
                    name={comment.profiles.name ?? undefined}
                    imgUrl={comment.profiles.img_url ?? undefined}
                    withTooltip={true}
                    label={
                      getInitials(
                        comment.profiles.name ??
                          comment.profiles.email.split('@').join(' ')
                      )!
                    }
                  />
                )}
                <p
                  className='text-xs text-primary-500'
                  title={format(
                    new Date(comment.created_at),
                    'MMM dd, yyyy HH:mm'
                  )}>
                  {formatDistanceToNow(new Date(comment.created_at), {
                    addSuffix: true
                  })}
                </p>
              </div>
            )}
            <p
              className={`break-normal text-neutral-700 text-sm font-normal font-['Inter']  ${
                idx > 0 && 'pl-11'
              }`}>
              {comment.content.split(/@\[([0-9a-z-]+)]/).map((c, idx) => {
                const mention = comment.comments_mentions.find(
                  cm => cm.profile_id === c
                )?.profiles?.name
                if (mention) {
                  return (
                    <span
                      key={`comment_${comment.id}_${idx}`}
                      className='font-semibold'>
                      @{mention}
                    </span>
                  )
                }
                return c
              })}
            </p>
          </div>
        ))}

        {!writingComment &&
          notification.type !== 'payout_resolved' &&
          notification.type !== 'statement_resolved' && (
            <Button
              hierarchy={'secondary'}
              size={'small'}
              className='mt-3 w-full'
              onPress={() => setWritingComment(notification.id)}
              label={
                notification.comments && notification.comments.length > 0
                  ? t`Reply`
                  : t`Add comment`
              }></Button>
          )}

        {writingComment === notification.id && (
          <div className='mt-3'>
            <CommentBox
              insertComment={(comment, mentions) =>
                insertComment(comment, notification.id, mentions)
              }
              onCancel={onCommentCancel}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default NotificationItem
